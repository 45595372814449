<template>
  <el-button class="reportButton" v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-button>
</template>
<script>
export default {
  name: 'ReportButton'
}
</script>
<style lang="scss" >
.reportButton {
  font-weight: normal;
  // 字体图标大小
  .iconfont {
    font-size: 20px;
    & + span {
      position: relative;
      top: -1px;
    }
  }
  // 按钮中图标和文字的间距
  &.el-button [class*="iconfont"] + span {
    margin-left: 2px;
  }
  &.el-button + &.el-button {
    margin-left: 12px;
  }
  // info 按钮样式
  &.el-button--info {
    color: #646a73;
    background: #f2f3f5;
    border-color: #f2f3f5;

    &:hover,
    &:focus {
      background: #3370ff;
      border-color: #3370ff;
      color: #fff;
    }
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:focus,
    &.is-disabled:active {
      color: #d0d3d6;
      background-color: #f2f3f5;
      border-color: #f2f3f5;
    }
  }

  // plain primary 按钮样式
  &.el-button--primary {
    color: #fff;
    background: #3370ff;
    border-color: #3370ff;
    &:hover {
      background: #3f78ff;
      border-color: #3f78ff;
    }
    &:focus {
      color: #fff;
      background: #3370ff;
      border-color: #3370ff;
    }
    &:active {
      background: #2155cd;
      border-color: #2155cd;
      color: #fff;
    }

    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:focus,
    &.is-disabled:active {
      color: #fff;
      background-color: #d0d3d6;
      border-color: #d0d3d6;
    }
  }

  // plain primary 按钮样式
  &.el-button--primary.is-plain {
    color: #3370ff;
    background: #fff;
    border: 1px solid rgba(38, 110, 255, 0.4);
    &:hover {
      background: rgba(51, 112, 255, 0.1);
      border: 1px solid rgba(51, 112, 255, 1);
      color: #3370ff;
    }
    &:focus {
      color: #3370ff;
      background: #fff;
      border: 1px solid rgba(38, 110, 255, 0.4);
    }
    &:active {
      background: rgba(51, 112, 255, 0.2);
      border: 1px solid rgba(51, 112, 255, 1);
      color: #3370ff;
    }

    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:focus,
    &.is-disabled:active {
      color: #d0d3d6;
      background: #f2f3f5;
      border: 1px solid rgba(208, 211, 214, 1);
    }
  }

  &.el-button {
    padding: 7px 8px;
    font-weight: normal;
    min-width: 80px;
    min-height: 36px;
  }
}
</style>
