/*
 * @Author: liuyang liuyang@qq.com
 * @Date: 2022-05-26 10:17:47
 * @LastEditors: liuyang liuyang@qq.com
 * @LastEditTime: 2022-05-26 10:23:43
 * @FilePath: \report-frontend\src\api\dataCount.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '@/utils/axios'

/**
 * 获取菜单数据统计
 * @param {*} params
 */
export function getMenuCount (params) {
  return axios({
    url: '/dataCount/getMenuCount',
    method: 'get',
    params
  })
}

/**
 * 获取数据统计
 * @param {*} params
 */
export function getReportCount (params) {
  return axios({
    url: '/dataCount/getReportCount',
    method: 'get',
    params
  })
}
