<!--
 * @Description:
 * @Author: Pengfei.Wang
 * @Date: 2022-06-27 16:03:21
 * @LastEditors: Pengfei.Wang
 * @LastEditTime: 2022-06-27 16:11:06
 * @version: 1.0
 * Copyright(C)中国软件与技术服务股份有限公司-版权所有
-->
<template>
  <el-dialog
    class="closeDialog"
    :show-close="false"
    :center="true"
    title="提示"
    :visible.sync="close"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="500px"
  >
    <span>检测到其它电脑或另一个浏览器，打开了本填报进行数据编辑。为了避免两个页面的数据保存冲突，本页将自动关闭。点击确定按钮，关闭页面</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="hanlerClose">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CloseDialog',
  computed: {
    ...mapGetters({ close: 'app/close' })
  },
  methods: {
    hanlerClose() {
      if (
        navigator.userAgent.indexOf('Firefox') !== -1 ||
        navigator.userAgent.indexOf('Chrome') !== -1
      ) {
        window.location.href = 'about:blank'
        window.close()
      } else {
        window.opener = null
        window.open('', '_self')
        window.close()
      }
    }
  }
}
</script>
<style lang="scss">
.closeDialog {
  .el-dialog {
    min-width: 500px;
  }
}
</style>
