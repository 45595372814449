import LayoutReport from '@/layout/Report.vue'
export default [
  {
    path: '/schedule',
    component: LayoutReport,
    children: [
      {
        path: 'list',
        name: 'scheduleList',
        component: () => import(/* webpackChunkName: "scheduleList" */ '../views/schedule/List.vue'),
        meta: {
          title: '任务信息列表'
        }
      }

    ]
  }
]
