/*
 * @Description:
 * @Author: Pengfei.Wang
 * @Date: 2022-06-17 17:12:41
 * @LastEditors: Pengfei.Wang
 * @LastEditTime: 2022-06-27 16:11:34
 * @version: 1.0
 * Copyright(C)中国软件与技术服务股份有限公司-版权所有
 */
import { Loading } from 'element-ui'
const state = {
  appEnv: process.env.VUE_APP_ENV,
  backendContextPath: process.env.VUE_APP_BACKEND_CONTEXT_PATH,
  sysConfig: null,
  platform: process.env.VUE_APP_ENV.includes('zrzzwl')
    ? 'zzwl'
    : process.env.VUE_APP_ENV.includes('zz')
      ? 'zz'
      : process.env.VUE_APP_ENV.includes('zy')
        ? 'zy'
        : process.env.VUE_APP_ENV.includes('gx')
          ? 'gx'
          : process.env.VUE_APP_ENV.includes('zl')
            ? 'zl'
            : process.env.VUE_APP_ENV.includes('bt')
              ? 'bt'
              : process.env.VUE_APP_ENV.includes('zr')
                ? 'zr'
                : '',
  sidebar: { collapse: false },
  close: false,
  spreadjsUrl: process.env.VUE_APP_SPREADJS_URL,
  loading: null,
  rateTitle: process.env.VUE_APP_RATE_TITLE || '',
  industryTitle: process.env.VUE_APP_INDUSTRY_TITLE || '',
  industryList:
    (process.env.VUE_APP_INDUSTRY_LIST &&
      JSON.parse(process.env.VUE_APP_INDUSTRY_LIST)) ||
    []
}
const getters = {
  appEnv: (state) => state.appEnv,
  backendContextPath: (state) => state.backendContextPath,
  sysConfig: (state) => state.sysConfig,
  platform: (state) => state.platform,
  sidebar: (state) => state.sidebar,
  close: (state) => state.close,
  spreadjsUrl: (state) => state.spreadjsUrl,
  rateTitle: (state) => state.rateTitle,
  industryTitle: (state) => state.industryTitle,
  industryList: (state) => state.industryList
}
const mutations = {
  // 打开多开提示弹出框
  SET_CLOSE: (state) => {
    state.close = true
  },
  // 设置系统配置
  SET_SYS_CONFIG: (state, sysConfig) => {
    state.sysConfig = sysConfig
  },
  // 设置左侧菜单栏
  SET_SIDEBAR: (state, sidebar) => {
    state.sidebar = Object.assign(state.sidebar, sidebar)
  },
  // 打开loading
  SET_LOADING_OPEN: (state, option = {}) => {
    if (!state.loading) {
      state.loading = Loading.service({
        lock: false,
        background: 'rgba(0, 0, 0, 0.7)',
        ...option
      })
    }
  },
  // 关闭loading
  SET_LOADING_CLOSE: (state) => {
    if (state.loading) {
      state.loading.close()
      state.loading = null
    }
  }
}
const actions = {
  // 打开多开提示弹出框
  setClose({ commit }) {
    commit('SET_CLOSE')
  },
  // 查询系统配置
  setSysConfig({ commit }, sysConfig) {
    commit('SET_SYS_CONFIG', sysConfig)
  },
  // 设置左侧菜单栏
  setSidebar({ commit }, sidebar) {
    commit('SET_SIDEBAR', sidebar)
  },
  // 打开loading
  loadingOpen({ commit }, option) {
    commit('SET_LOADING_OPEN', option)
  },
  // 关闭loading
  loadingClose({ commit }) {
    commit('SET_LOADING_CLOSE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
