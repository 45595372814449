import { setToken, getToken, removeToken } from '@/utils'
// import {
//   reportFilledInfoListBy,
//   reportFilledInfoList,
//   awaitApproval
// } from '@/api/reportFilledInfo'
// import { noticeReceiveList } from '@/api/notice'
// import { dictionarySelectDictionaryListByKey } from '@/api/dictionary'
import { getMenuCount } from '@/api/dataCount'
const state = {
  token: getToken() || null,
  userInfo: null,
  asideList: [],
  asideNum: {}
}
const getters = {
  token: (state) => state.token,
  userInfo: (state) => state.userInfo,
  asideList: (state) => state.asideList,
  asideNum: (state) => state.asideNum
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  // 设置用户信息
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  // 设置用户信息
  SET_ASIDE_LIST: (state, asideList) => {
    state.asideList = asideList
  },
  // 设置用户信息
  SET_ASIDE_Num: (state, asideNum) => {
    state.asideNum = asideNum
  }
}
const actions = {
  setToken({ commit }, token) {
    setToken(token)
    commit('SET_TOKEN', token)
    sessionStorage.setItem('frontendToken', token)
    localStorage.setItem('frontendToken', token)
  },
  setUserInfo({ commit }, userInfo) {
    commit('SET_USER_INFO', userInfo)
  },
  // 前端 登出
  fedLogOut({ commit }) {
    commit('SET_TOKEN', null)
    sessionStorage.setItem('frontendToken', null)
    localStorage.setItem('frontendToken', null)
    localStorage.setItem('loginLogId', null)
    localStorage.setItem('defaultPassword', null)
    localStorage.setItem('loginType', null)
    removeToken()
  },
  setAsideList({ commit }, asideList) {
    commit('SET_ASIDE_LIST', asideList)
  },

  setAsideNum({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        // data1是审批数量，data2是填报数量，data3是帮忙填报数量，data4是消息通知数量
        const data = await getMenuCount()
        const asideList = state.asideList
        commit('SET_ASIDE_Num', data.data)
        if (data.code === 200) {
          if (state.userInfo.umsUser.roleType === 0) {
            // asideList[1].count = data.data.data1
            // asideList[2].count = data.data.data2 + data.data.data5 + data.data.data7
            // asideList[3].count = data.data.data3
            // asideList[6].count = data.data.data4
            if (
              process.env.VUE_APP_ENV.includes('zrlocal') ||
              process.env.VUE_APP_ENV.includes('zrshow') ||
              process.env.VUE_APP_ENV.includes('zrtest') ||
              process.env.VUE_APP_ENV.includes('zy') ||
              process.env.VUE_APP_ENV.includes('ct')
            ) {
              asideList[1] && (asideList[1].count = data.data.data9)
              asideList[2] && (asideList[2].count = data.data.data1)
              asideList[3] &&
                (asideList[3].count =
                  data.data.data2 + data.data.data5 + data.data.data7)
              asideList[4] && (asideList[4].count = data.data.data3)
              asideList[7] && (asideList[7].count = data.data.data4)
            } else {
              asideList[1] && (asideList[1].count = data.data.data1)
              asideList[2] &&
                (asideList[2].count =
                  data.data.data2 + data.data.data5 + data.data.data7)
              asideList[3] && (asideList[3].count = data.data.data3)
              asideList[6] && (asideList[6].count = data.data.data4)
            }
            // asideList[0].count = data.data.data1
            // asideList[1].count = data.data.data2 + data.data.data5 + data.data.data7
            // asideList[2].count = data.data.data3
            // asideList[5].count = data.data.data4
          } else if (state.userInfo.umsUser.roleType === 1) {
            if (
              process.env.VUE_APP_ENV.includes('zrlocal') ||
              process.env.VUE_APP_ENV.includes('zrtest') ||
              process.env.VUE_APP_ENV.includes('zrshow') ||
              process.env.VUE_APP_ENV.includes('zy') ||
              process.env.VUE_APP_ENV.includes('ct')
            ) {
              asideList[1] && (asideList[1].count = data.data.data9)
              asideList[2] && (asideList[2].count = data.data.data1)
              asideList[3] &&
                (asideList[3].count =
                  data.data.data2 + data.data.data5 + data.data.data7)
              asideList[4] && (asideList[4].count = data.data.data3)
              asideList[7] && (asideList[7].count = data.data.data4)
            } else {
              asideList[1] && (asideList[1].count = data.data.data1)
              asideList[2] &&
                (asideList[2].count =
                  data.data.data2 + data.data.data5 + data.data.data7)
              asideList[3] && (asideList[3].count = data.data.data3)
              asideList[6] && (asideList[6].count = data.data.data4)
            }

            // asideList[0].count = data.data.data1
            // asideList[1].count = data.data.data2+data.data.data5
            // asideList[2].count = data.data.data3
            // asideList[5].count = data.data.data4
          } else if (state.userInfo.umsUser.roleType === 2) {
            // asideList[1].count = data.data.data1
            // asideList[2].count = data.data.data2 + data.data.data5 + data.data.data7
            // asideList[3].count = data.data.data3
            // asideList[5].count = data.data.data4

            asideList[0] && (asideList[0].count = data.data.data1)
            asideList[1] &&
              (asideList[1].count =
                data.data.data2 + data.data.data5 + data.data.data7)
            asideList[2] && (asideList[2].count = data.data.data3)
            asideList[4] && (asideList[4].count = data.data.data4)
          } else if (state.userInfo.umsUser.roleType === 3) {
            asideList[0] && (asideList[0].count = data.data.data3)
          }
        }
        commit('SET_ASIDE_LIST', asideList)
      } catch (error) {
        console.log(error)
      }
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
