<template>
  <el-form
    class="reportForm"
    ref="reportFormRef"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
  /></el-form>
</template>
<script>
export default {
  name: 'ReportForm'
}
</script>
<style lang="scss">
.reportForm {
  .el-form-item__content {
    font-size: 16px;
  }

  .el-form-item::before,
  .el-form-item::after {
    display: table;
    content: none;
  }

  .el-form-item__content::before,
  .el-form-item__content::after {
    display: table;
    content: none;
  }
  .el-form-item__label {
    font-weight: 500;
    color: #000;
  }
  .el-input__count {
    .el-input__count-inner {
      font-size: 16px;
      color: #d0d3d6;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>
