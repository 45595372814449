var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aside"},[_c('div',{staticClass:"aside__main animate__animated animate__slideInLeft",staticStyle:{"animation-duration":"500ms"}},[_c('el-menu',{staticClass:"aside-menu",attrs:{"default-active":_vm.defaultActive,"text-color":"#1F2329","active-text-color":"#3370FF","collapse":_vm.appSidebar.collapse,"collapse-transition":false,"router":""}},_vm._l((_vm.asideList),function(item,index){return _c('el-menu-item',{key:index,attrs:{"index":item.url}},[(!item.activeList.includes(_vm.$route.path))?_c('i',{class:item.icon}):_vm._e(),(item.activeList.includes(_vm.$route.path))?_c('img',{staticClass:"aside-menu__gif",attrs:{"src":item.gif,"alt":""}}):_vm._e(),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.label))]),(
            item.count != 0 && !_vm.appSidebar.collapse && _vm.appPlatform != 'gx'
          )?_c('CountTo',{staticClass:"aside__badge",class:{
            'animate__animated animate__bounceInRight': !_vm.isInitAside,
            'animate__animated animate__bounce': item.animate,
          },attrs:{"startVal":!_vm.isInitAside ? 1 : item.count >= 100 ? 99 : item.count,"endVal":item.count >= 100 ? 99 : item.count,"suffix":item.count >= 100 ? '+' : '',"duration":3000}}):_vm._e()],1)}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.appSidebar.collapse),expression:"appSidebar.collapse"}],staticClass:"aside-open",on:{"click":function($event){return _vm.handlersetCollapse(false)}}},[_c('i',{staticClass:"icon iconfont ic_a-doubleright"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.appSidebar.collapse),expression:"!appSidebar.collapse"}],staticClass:"aside-close",class:{
      'animate__animated animate__fadeIn animate__delay-1s': !_vm.isInitAside,
    },on:{"click":function($event){return _vm.handlersetCollapse(true)}}},[_c('i',{staticClass:"icon iconfont ic_doubleleft"})])])}
var staticRenderFns = []

export { render, staticRenderFns }