/*
 * @Description:
 * @Author: Pengfei.Wang
 * @Date: 2022-10-10 14:36:37
 * @LastEditors: Pengfei.Wang
 * @LastEditTime: 2022-10-14 15:33:40
 * @version: 1.0
 * Copyright(C)中国软件与技术服务股份有限公司-版权所有
 */
import LayoutReport from '@/layout/Report.vue'
export default [
  {
    path: '/workflow',
    component: LayoutReport,
    children: [
      {
        path: 'workflowDefinition',
        name: 'workflowDefinition',
        component: () => import(/* webpackChunkName: "workflowList" */ '../views/workflow/definition.vue'),
        meta: {
          title: '流程定义列表'
        }
      },
      {
        path: 'workflowDeployment',
        name: 'workflowDeployment',
        component: () => import(/* webpackChunkName: "workflowList" */ '../views/workflow/deployment.vue'),
        meta: {
          title: '流程部署列表'
        }
      },
      {
        path: 'bpmn-modeler',
        name: 'bpmn-modeler',
        component: () => import(/* webpackChunkName: "workflowList" */ '../views/workflow/bpmn-modeler.vue'),
        meta: {
          title: '流程设计器'
        }
      }

    ]
  }
]
