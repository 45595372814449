import Vue from 'vue'
import Vuex from 'vuex'
// 项目基础设置
import app from './modules/app'
import user from './modules/user'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    app,
    user
  }
})

export default store
