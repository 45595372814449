import Cookies from 'js-cookie'
import jsSHA from 'jssha'
import CryptoJS from 'crypto-js'
const TokenKey = 'frontendToken'

export function encrypt(str) {
  return CryptoJS.AES.encrypt(str, TokenKey).toString()
}

export function decrypt(str) {
  const bytes = CryptoJS.AES.decrypt(str, TokenKey)
  return bytes.toString(CryptoJS.enc.Utf8)
}

/**
 * 设置token
 */
export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

/**
 * 对象的值去除前后空格
 * @param {Object} obj
 */
export function objTrim (obj) {
  const result = JSON.parse(JSON.stringify(obj))
  // eslint-disable-next-line array-callback-return
  Object.keys(result).map(key => {
    if (typeof result[key] === 'string') {
      result[key] = result[key].trim()
    }
  })
  return result
}

/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
String.prototype.hashCodeAbs = function () {
  let ret = 0
  let i = 0
  let len = this.length
  for (ret = 0, i = 0, len = this.length; i < len; i++) {
    ret = (31 * ret + this.charCodeAt(i)) << 0
  }
  return Math.abs(ret)
}
// 对象排序
export function orderKeyByHashCodeAbs (obj) {
  const newData = {}
  Object.keys(obj)
    .sort(function (a, b) {
      return b.hashCodeAbs() - a.hashCodeAbs()
    })
    // eslint-disable-next-line array-callback-return
    .map(key => {
      if (typeof obj[key] === 'number' || typeof obj[key] === 'boolean') {
        newData[key] = '' + obj[key]
      } else if (Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          newData[key] = obj[key].map(item => {
            if (typeof item === 'number') {
              return '' + item
            } else {
              return item
            }
          })
        }
      } else if (obj[key] !== null) {
        newData[key] = '' + obj[key]
      }
    })
  return newData
}
// 对象加密
export function query (obj) {
  obj = objTrim(obj)
  obj = orderKeyByHashCodeAbs(obj)
  obj.ENCODE = shaEncode(JSON.stringify(obj))
  return obj
}

/* eslint new-cap: ["error", { "newIsCap": false }] */
export function shaEncode (str, key = 'frontend$frontend') {
  const shaObj = new jsSHA('SHA-1', 'TEXT')
  shaObj.update(str)
  shaObj.update(key)
  const result = shaObj.getHash('HEX')
  return result
}

/**
 * 树形数据转换
 * @param {Object} data
 * @param {String} id
 * @param {String} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  const res = []
  const temp = {}
  for (let i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (let k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]].children) {
        temp[data[k][pid]].children = []
      }
      if (!temp[data[k][pid]]._level) {
        temp[data[k][pid]]._level = 1
      }
      data[k]._level = temp[data[k][pid]]._level + 1
      temp[data[k][pid]].children.push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

export function sleep(time = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
