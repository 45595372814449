/*
 * @Description:
 * @Author: Pengfei.Wang
 * @Date: 2022-06-02 15:58:06
 * @LastEditors: Pengfei.Wang
 * @LastEditTime: 2022-08-17 14:29:52
 * @version: 1.0
 * Copyright(C)中国软件与技术服务股份有限公司-版权所有
 */
import LayoutMain from '@/layout/Main.vue'
export default [
  {
    path: '',
    component: LayoutMain,
    children: [
      {
        path: '/',
        name: 'loginView',
        component: () => import(/* webpackChunkName: "loginView" */ '../views/login/index.vue'),
        meta: {
          title: process.env.VUE_APP_ENV.includes('zzwl') ? '企业安全风险评级' : process.env.VUE_APP_ENV.includes('zl') ? '信息资产管理系统' : process.env.VUE_APP_ENV.includes('gx') ? '企业信用评级' : process.env.VUE_APP_ENV.includes('zy') ? '安全环保信息系统' : '信息快报系统'
        }
      },
      {
        path: '/error/404',
        name: 'Err404',
        meta: {
          title: '404'
        },
        component: () =>
          import(/* webpackChunkName: "err404" */ '@/views/error/404.vue')
      },
      {
        path: '/error/401',
        name: 'Err401',
        meta: {
          title: '401'
        },
        component: () =>
          import(/* webpackChunkName: "err401" */ '@/views/error/401.vue')
      },
      {
        path: '/error/500',
        name: 'Err500',
        meta: {
          title: '500'
        },
        component: () =>
          import(/* webpackChunkName: "err500" */ '@/views/error/500.vue')
      }
    ]
  }
]
